@import 'core';

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  line-height: 1.15;
  height: 100%;
  width: 100%;
}

body {
  position: relative;
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 1.6rem;
  color: $font-color;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  word-wrap: break-word;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  font-family: 'Noto Sans', sans-serif;
}

a {
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  text-decoration: none !important;
  &:not([href]) {
    pointer-events: none;
  }
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Raleway', sans-serif;
}

h1 {
  font-size: 2.25rem;
  line-height: 46px;
}

h2 {
  font-size: 2rem;
  line-height: 41px;
}

h3 {
  font-size: 1.75rem;
  line-height: 36px;
}

h4 {
  font-size: 1.5rem;
  line-height: 30px;
}

h5 {
  font-size: 1.25rem;
  line-height: 23px;
}

h6 {
  font-size: 1.125rem;
  line-height: 23px;
}

#root {
  height: 100%;
}

.container {
  max-width: $content-width;
  padding: 0;
}

@media (min-width: $desktop-screen-min) {
  .container {
    padding: 0;
  }
}

.home-layout,
.about-us-layout,
.contact-us-layout {
  margin: auto;
  max-width: 1440px;
}
/* HomePage styles */
.home-layout {
  > main {
    display: grid;
    grid-template-rows: 50rem min-content min-content;
  }
}

/* HomePage styles */
.contact-us-layout {
  > main {
    padding: 2rem;
    background-color: rgba(244, 244, 244, 1);
  }
}
