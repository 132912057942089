@import url('https://fonts.googleapis.com/css2?family=Raleway&family=Noto+Sans&display=swap');

// Color-variables
$font-color: #343434;
$content-width: 1440px;

$main-yellow: #f2ce4f; /* 242 206 79 */
$dark-bluish-purple: #223CCF;
$dark-green: #002929 /* 0 41 41 */;
$main-color:  #465A3B;
$soft-purple: #28282b;
$seaweed-brown: #978E7D; /* 151 142 125 */
$near-black: #100000 /* 16 0 0 */;

// Media query variables
$mobile-screen-min: 320px;
$mobile-screen-max: 767px;
$tab-screen-min: 768px;
$tab-screen-max: 1024px;
$desktop-screen-min: 1025px;
$desktop-screen-max: 1280px;
$large-desktop-screen-min: 1281px;

// default mixins
@mixin translateY($e) {
    -webkit-transform: translateY($e);
    -moz-transform: translateY($e);
    transform: translateY($e);
}

@mixin transition($e) {
    -webkit-transform: transition($e);
    -moz-transform: transition($e);
    transform: transition($e);
}