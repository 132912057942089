@import '../../core.scss';

.services-section {
  clear: both;
  position: relative;
  padding: 7em 0;
  @media screen and (max-width: $tab-screen-min) {
    padding: 3em 0;
  }
  &.border-bottom {
    border-bottom: 1px solid lighten(#080808, 85%);
  }
}

.animate-box {
  .js & {
    opacity: 0;
  }
}

.services-container {
  max-width: 1140px;
  position: relative;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
}

.services-heading {
  margin-bottom: 5em;
  h2 {
    font-size: 36px;
    margin-bottom: 20px;
    line-height: 1.5;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    padding-bottom: 10px;
    &:before {
      position: absolute;
      bottom: 0;
      content: '';
      width: 50px;
      margin-left: -25px;
      height: 2px;
      left: 50%;
      background: $main-color;
    }
  }
  p {
    font-size: 20px;
    line-height: 1.5;
    color: lighten($font-color, 10%);
  }
}

.feature-left {
  float: left;
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  .icon {
    float: left;
    text-align: center;
    width: 15%;
    svg {
      display: table-cell;
      vertical-align: middle;
      font-size: 40px;
      color: $main-color;
      transition: all 0.33s;
      transition-timing-function: ease-in-out;
      &:hover {
        color: lighten($main-color, 20%);
        font-size: 44px;
      }
    }
  }
  .feature-copy {
    float: right;
    width: 80%;
    @media screen and (max-width: $tab-screen-min) {
    }
    h3 {
      color: lighten($font-color, 10%);
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    p {
      color: lighten($font-color, 10%);
    }
  }
}
