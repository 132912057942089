@import "../../core";

.banner-wrapper {
  display:  grid;
  grid-template-rows: 0.8fr 1.5fr 5rem;
  height: 100%;
  align-items: center;
  .banner-top {
    h1{
      font-size: 4.5rem;
      font-weight: 700;
      color: $font-color;
    }
  }
  .banner-middle {
    background-color: rgba(244, 244, 244, 0.7);
    justify-self: center;
    max-width: 45rem;
    padding: 2rem;
    font-size: 1.7rem;
    font-weight: 700;
    color: $font-color;
  }
}